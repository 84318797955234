import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { getUserData, updateUser } from "../../controllers/userController";
import GlobalStyle, {
  BackBar,
  BackButton,
  ErrorComponent,
} from "../../styles/global";
import { Container, Content, ContentSection } from "../../styles/styles";
import {
  Aviso,
  ErrorMessage,
  Form,
  InputField,
  Label,
  RequiredText,
  SelectField,
  SubmitButton,
} from "./styles";

const UserEdit = () => {
  const user = JSON.parse(localStorage.getItem("user")); // Usuário autenticado
  const { id } = useParams(); // ID do usuário passado por parâmetro
  const navigate = useNavigate();

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmarSenha, setConfirmarSenha] = useState("");
  const [cpf, setCpf] = useState("");
  const [cargo, setCargo] = useState("");
  const [unidade, setUnidade] = useState("");
  const [secao, setSecao] = useState("");
  const [setor, setSetor] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [notFound, setNotFound] = useState(false);
  const isEditingOwnAccount = user.idusuario === parseInt(id, 10); // Verifica se está editando o próprio usuário
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (user.nivel !== 1 && !isEditingOwnAccount) {
      alert("Você não tem permissão para editar este usuário.");
      navigate("/");
    }

    if (id) {
      getUserData(id)
        .then((data) => {
          if (!data) {
            setNotFound(true);
          } else {
            setNome(data.nome);
            setEmail(data.email);
            setCpf(data.cpf);
            setCargo(data.cargo);
            setUnidade(data.unidade);
            setSecao(data.secao);
            setSetor(data.setor);
          }
        })
        .catch((err) => setErrorMessage(err.message) && setNotFound(true));
    }
  }, [id, user.nivel, isEditingOwnAccount, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isEditingOwnAccount && senha !== confirmarSenha) {
      toast.error("As senhas não conferem.");
      return;
    }

    let formData = {
      nome,
      email,
      cpf,
      cargo,
      unidade,
      secao,
      setor,
    };

    if (isEditingOwnAccount && senha) {
      formData.senha = senha;
    }

    try {
      await updateUser(
        id,
        { nivelAuth: user.nivel, idAuth: user.idusuario },
        formData
      );
      toast.success("Usuário atualizado com sucesso!", {
        autoClose: 3000,
        onClose: () => navigate("/"),
      });
      //navigate("/confirmacao-editar-usuario");
      //navigate(-1);
    } catch (error) {
      toast.error("Erro ao atualizar o usuário: " + error.mensagem);
    }
  };

  if (notFound) {
    return (
      <>
        <ToastContainer />
        <GlobalStyle />
        <ContentSection fullWidth height="100vh">
          <Content direction="column" align="center">
            <Aviso light>
              <p>Úsuario não encontrado</p>
              <BackButton onClick={() => navigate(-1)}>← Voltar</BackButton>
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </Aviso>
          </Content>
        </ContentSection>
      </>
    );
  }

  return (
    <>
      <ToastContainer />
      <GlobalStyle />
      <Container
        style={{
          background: `linear-gradient(10deg, #34C759 20%, #00A565 0%)`,
        }}
        fullWidth
        direction="column"
        align="center"
        padding="0px"
        height="100%"
      >
        <Container direction="column" gap="40px" fullWidth>
          <BackBar>
            <BackButton onClick={() => navigate(-1)}>← Voltar</BackButton>
            <BackButton onClick={() => navigate("/")}>X</BackButton>
          </BackBar>

          <ContentSection fullWidth padding="0px">
            <Content light padding="0px" sx={{ paddingTop: "40px" }}>
              <Form onSubmit={handleSubmit}>
                <p>Meu perfil</p>
                <Container
                  fullWidth
                  direction="column"
                  gap="14px"
                  padding="20px"
                >
                  <Label htmlFor="cpf">CPF</Label>
                  <InputField
                    type="text"
                    placeholder="CPF"
                    value={cpf}
                    disabled
                  />
                  <RequiredText>Obrigatório *</RequiredText>
                  <Label htmlFor="email">Email</Label>
                  <InputField
                    type="email"
                    placeholder="Email do usuário"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <RequiredText>Obrigatório *</RequiredText>

                  {isEditingOwnAccount && (
                    <>
                      <InputField
                        type="password"
                        placeholder="Nova Senha"
                        value={senha}
                        onChange={(e) => setSenha(e.target.value)}
                      />
                      <RequiredText>Obrigatório *</RequiredText>

                      <InputField
                        type="password"
                        placeholder="Confirmar Senha"
                        value={confirmarSenha}
                        onChange={(e) => setConfirmarSenha(e.target.value)}
                      />
                      <RequiredText>Obrigatório *</RequiredText>
                    </>
                  )}

                  {user.nivel == 1 && (
                    <>
                      <Label htmlFor="nome">Nome</Label>
                      <InputField
                        type="text"
                        placeholder="Nome do usuário"
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                        required
                      />
                      <RequiredText>Obrigatório *</RequiredText>

                      <Label htmlFor="nome">Cargo</Label>
                      <SelectField
                        value={cargo}
                        onChange={(e) => setCargo(e.target.value)}
                      >
                        <option value="">Selecione o Cargo</option>
                        <option value="Membro da Diretoria">
                          Membro da Diretoria
                        </option>
                        <option value="Gerente Geral">Gerente Geral</option>
                        <option value="Gerente Operacional">
                          Gerente Operacional
                        </option>
                        <option value="Gerente Corporativo">
                          Gerente Corporativo
                        </option>
                        <option value="Gestor">Gestor</option>
                        <option value="Coordenador">Coordenador</option>
                        <option value="Supervisor">Supervisor</option>
                        <option value="Emocionador">Emocionador</option>
                        <option value="Extraordinário">Extraordinário</option>
                        <option value="Gerente TCF">Gerente TCF</option>
                        <option value="Lider">Lider</option>
                      </SelectField>
                      <RequiredText>Obrigatório *</RequiredText>
                      <Label htmlFor="unidade">Unidade</Label>
                      <SelectField
                        value={unidade}
                        onChange={(e) => setUnidade(e.target.value)}
                      >
                        <option value="">Selecione a Unidade</option>
                        <option value="Holding">Holding</option>
                        <option value="Alegro">Alegro</option>
                        <option value="Alexânia">Alexânia</option>
                        <option value="Araxá">Araxá</option>
                        <option value="Atibaia">Atibaia</option>
                        <option value="Caeté">Caeté</option>
                      </SelectField>
                      <RequiredText>Obrigatório *</RequiredText>

                      <Label htmlFor="setor">Setor</Label>
                      <SelectField
                        id="setor"
                        value={setor}
                        onChange={(e) => setSetor(e.target.value)}
                      >
                        <option value="" disabled>
                          Selecione o Setor
                        </option>
                        <option value="Cozinha">Cozinha</option>
                        <option value="Administrativo e Financeiro">
                          Administrativo e Financeiro
                        </option>
                        <option value="Agências">Agências</option>
                        <option value="Conservação e Limpeza">
                          Conservação e Limpeza
                        </option>
                        <option value="Contabilidade">Contabilidade</option>
                        <option value="CSC">CSC</option>
                        <option value="DP">DP</option>
                        <option value="ESG e SSO">ESG e SSO</option>
                        <option value="Eventos">Eventos</option>
                        <option value="Compras">Compras</option>
                        <option value="Gerente Geral">Gerente Geral</option>
                        <option value="Gerente de relacionamento">
                          Gerente de relacionamento
                        </option>
                        <option value="Gerente noturno">Gerente noturno</option>
                        <option value="Governança">Governança</option>
                        <option value="Inteligência do negócio">
                          Inteligência do negócio
                        </option>
                        <option value="Lavanderia">Lavanderia</option>
                        <option value="Lazer/Jota City">Lazer/Jota City</option>
                        <option value="Lojas">Lojas</option>
                        <option value="Mais Tauá">Mais Tauá</option>
                        <option value="Manutenção e Jardinagem">
                          Manutenção e Jardinagem
                        </option>
                        <option value="Marketing">Marketing</option>
                        <option value="Martech">Martech</option>
                        <option value="Minibar">Minibar</option>
                        <option value="Orçamento e gestão">
                          Orçamento e gestão
                        </option>
                        <option value="Parque Aquático">Parque Aquático</option>
                        <option value="Pós venda Mais Tauá">
                          Pós venda Mais Tauá
                        </option>
                        <option value="Qualidade e Experiência do Cliente">
                          Qualidade e Experiência do Cliente
                        </option>
                        <option value="Recepção/ Guest">Recepção/ Guest</option>
                        <option value="Reservas">Reservas</option>
                        <option value="Restaurante e bares">
                          Restaurante e bares
                        </option>
                        <option value="RM e distribuição">
                          RM e distribuição
                        </option>
                        <option value="Segurança">Segurança</option>
                        <option value="Setor">Setor</option>
                        <option value="SPA">SPA</option>
                        <option value="Supply Chain">Supply Chain</option>
                        <option value="TCF">TCF</option>
                        <option value="Tech Hub">Tech Hub</option>
                        <option value="Unisorriso">Unisorriso</option>
                        <option value="Vendas externas">Vendas externas</option>
                        <option value="Vendas Internas">Vendas Internas</option>
                        <option value="Extraordinário">Extraordinário</option>
                      </SelectField>
                    </>
                  )}

                  {/* {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>} */}
                  {errorMessage && (
                    <ErrorComponent message={errorMessage} duration={8000} />
                  )}
                  <SubmitButton
                    type="submit"
                    style={{ backgroundColor: "#FFCC00", alignSelf: "center" }}
                  >
                    Salvar
                  </SubmitButton>
                </Container>
              </Form>
            </Content>
          </ContentSection>
        </Container>
      </Container>
    </>
  );
};

export default UserEdit;
